import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

const dataLoadingOptions = { getterName: 'getMessages' };
const paginationOptions = { itemsName: 'messages' };
const searchOptions = {
  initialSearchDataStates: ['senderId', 'messageId', 'searchTerm', 'dates'],
  customTypes: {
    senderId: 'number',
    messageId: 'number',
    dates: 'array',
  },
};
const messagesListHeaders = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'ID', value: 'id' },
  { text: 'Created Date', value: 'dateCreated' },
  { text: 'Message Type', value: 'messageType' },
  { text: 'Sender', value: 'senderName' },
  { text: '', value: 'actions', width: '1%' },
]);

export default {
  name: 'Messages',
  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],
  data() {
    return {
      msgModalTitle: '',
      showMsgModal: false,
      messagesListHeaders,
    };
  },
  computed: {
    ...mapState('messages', ['message', 'messages']),
    ...mapState('accounts', ['accounts']),
    ...mapGetters('accounts', ['accountNameById', 'accountsOptions']),

    searchFieldsConfig() {
      return [
        {
          type: 'text', value: 'searchTerm', label: 'Order ID', flex: 'lg6',
        },
        {
          type: 'text', value: 'messageId', label: 'Message ID', flex: 'lg6',
        },
        {
          type: 'combobox',
          value: 'senderId',
          label: 'Sender',
          items: this.accountsOptions,
          flex: 'lg6',
        },
        {
          value: 'dates', label: 'Date Range', type: 'daterange', flex: 'lg6',
        },
      ];
    },
  },
  created() {
    this.getAccounts({ activeOnly: true });
  },
  methods: {
    ...mapActions('messages', ['getMessage', 'getMessages']),
    ...mapActions('accounts', ['getAccounts']),
    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },
    async showMessage(id) {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getMessage(id);
        if (this.message) {
          this.msgModalTitle = `Message ${id}`;
          this.showMsgModal = true;
        } else {
          this.$VBlackerTheme.notification.warning('Empty data');
        }
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
