var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-layout',{attrs:{"column":""}},[_c('v-blacker-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('v-blacker-layout',{attrs:{"align-center":""}},[_c('v-blacker-spacer'),_c('span',{staticClass:"vbt-margin-r-10",staticStyle:{"font-size":"17px"}},[_vm._v(" All accounts ")]),_c('v-blacker-switch',{staticClass:"custom-search-toggle",attrs:{"color":"info"},on:{"change":_vm.changeAccountsState}})],1)]},proxy:true}]),model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('v-blacker-content-box',{attrs:{"is-loading-process":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"}},[_c('v-blacker-table',{attrs:{"headers":_vm.messagesListHeaders,"items":_vm.messages},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var id = ref.item.orderId;
return [_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'order',
            params: { id: id },
          }}},[_vm._v(" "+_vm._s(id)+" ")])]}},{key:"dateCreated",fn:function(ref){
          var dateCreated = ref.item.dateCreated;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(dateCreated,'local', '-'))+" ")]}},{key:"actions",fn:function(ref){
          var id = ref.item.id;
return [_c('v-blacker-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-blacker-button',_vm._g({attrs:{"icon":"","icon-name":"remove_red_eye","color":"info"},on:{"click":function($event){return _vm.showMessage(id)}}},on))]}}],null,true)},[_vm._v(" Show message ")])]}}])}),_c('v-blacker-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1),_c('v-blacker-modal-window',{attrs:{"title":_vm.msgModalTitle,"hide-submit-btn":""},model:{value:(_vm.showMsgModal),callback:function ($$v) {_vm.showMsgModal=$$v},expression:"showMsgModal"}},[_c('v-blacker-code-editor',{attrs:{"value":_vm.message,"max-height":"500px","readonly":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }